import { Component, ElementRef, Input, ViewChild, Renderer2, AfterViewInit, SecurityContext } from "@angular/core";
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: "ngbd-modal",
  templateUrl: "./modal3.component.html"
})
export class Modal3Component implements AfterViewInit{

  @ViewChild('focar', { static: false }) public buttonPraFocar: ElementRef;
  @ViewChild('ok', { static: false }) public buttonOkPraFocar: ElementRef;
  @Input() public title: string;
  @Input() public message: string;
  @Input() public messageHTML: string;
  @ViewChild('messageHTML', { static: false }) messageHTMLClean!: ElementRef<HTMLElement>;
  @Input() public handle: string;

  constructor(public activeModal: NgbActiveModal, private renderer: Renderer2, private sanitizer: DomSanitizer) {
  }

  public ngAfterViewInit(): void {
    const cleaned = this.sanitizer.sanitize(SecurityContext.HTML, this.messageHTML);
    this.renderer.setProperty(this.messageHTMLClean.nativeElement, 'innerHTML', cleaned);
  }

  passBack(saida: string) {
    this.activeModal.close(saida);
  }

}