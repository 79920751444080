




export const environment = {
  production: false,
  //OAuth2 WSO2 GovBr - HOM (tá igual de produção)
  clientId: 'HRZb0p61qc4iZPHfbst9cCyPJiwa',
  redirectUri: 'https://homologacao.dividaativa.ciasc.gov.br/DividaAtiva/dlogin',
  urlProvider: 'https://acesso.ciasc.sc.gov.br',
  urlApiGovBr: 'https://api.acesso.gov.br',
  urlServicosGovBr: 'https://sso.acesso.gov.br',

  URLPADRAOAPI: "https://dividaativa.ciasc.gov.br/homologacao/divida-ativa-api/",
  URLPADRAO: "https://homologacao.dividaativa.ciasc.gov.br/DividaAtiva/dlogin",
  //LOGIN SAU
  URLLOGIN: "https://sau.sc.gov.br/cas/login?service=",
  URLLOGOUT: "https://sau.sc.gov.br/cas/logout?service=",
  //ASSINADOR LOCAL
  URLASSINADOR: "http://localhost:9090/api/"
  
  };
  