<div class="cabecalho-modal modal-header" fxLayout="column" fxLayoutAlign="center center" >
    <div class="espacador"></div>
    <h5 class="titulo-modal" id="titulo-modal" *ngIf="title > ''">{{ title }}</h5>
    <button type="button" class="btn btn-close" id="xis" aria-label="Close"
      (click)="activeModal.dismiss('clicando no xis')"
      onmouseover="window.document.getElementById('xis').style.backgroundColor='#f00'; window.document.getElementById('xis').style.color='#fff'"
      onmouseout="window.document.getElementById('xis').style.backgroundColor='#eee'; window.document.getElementById('xis').style.color='#000'">
      <span aria-hidden="true" style="font-size: 1.4rem; font-weight: lighter;">&times;</span>
    </button>
</div>

<div class="corpo-texto">
  <form>
    <div #messageHTML class="mensagem-pre">
      {{ message }}
    </div>
    <div *ngIf="handle == 'ok'" class="botoes-inline">
      <div class="botoes">
        <button #ok type="button" class="btn btn-success botao-espaco" (click)="passBack('ok')">Ok</button>
      </div>
      <div class="botoes">
        <button type="button" class="btn btn-warning botao-espaco" (click)="activeModal.dismiss('clicando em Cancelar')">Cancelar</button>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer rodape-modal" *ngIf="handle != 'ok'">
  <button #focar type="button" class="btn btn-outline-dark" (click)="activeModal.close()">
    Fechar
  </button>
</div>